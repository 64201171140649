/* 
@font-face{
  font-family:"Radikal W01 Medium";
  src:url("Fonts/1565083/10fd958a-ca77-4dfa-9d82-aa8c70208b87.woff2") format("woff2"),url("Fonts/1565083/a583bfda-a81b-49ea-afde-85a5035cf5bc.woff") format("woff");
}
@font-face{
  font-family:"Radikal W01 Bold";
  src:url("Fonts/1565091/237e7a51-7fe7-4228-879f-ee12be361e89.woff2") format("woff2"),url("Fonts/1565091/c599e32d-4281-4f4b-915f-1785ffa4fcaa.woff") format("woff");
} */

@font-face {
  font-family: "Radikal W01 Regular";
  src: url("Fonts/1565075/60616067-2e9b-43d8-89d3-a4df4b1a4085.woff2")
      format("woff2"),
    url("Fonts/1565075/592b7d02-7d84-4f43-aabd-983f8493582f.woff")
      format("woff");
}

.App {
  text-align: center;
  font-family: Radikal;
}

.App-body {
  font-family: "Radikal W01 Regular";
  background: -webkit-linear-gradient(
      217deg,
      rgba(255, 0, 0, 0.8),
      rgba(255, 0, 0, 0) 70.71%
    ),
    linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 70.71%),
    linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 70.71%); /* For Chrome and Safari */
  background: -moz-linear-gradient(
      217deg,
      rgba(255, 0, 0, 0.8),
      rgba(255, 0, 0, 0) 70.71%
    ),
    linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 70.71%),
    linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 70.71%); /* For old Fx (3.6 to 15) */
  background: -ms-linear-gradient(
      217deg,
      rgba(255, 0, 0, 0.8),
      rgba(255, 0, 0, 0) 70.71%
    ),
    linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 70.71%),
    linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 70.71%); /* For pre-releases of IE 10*/
  background: -o-linear-gradient(
      217deg,
      rgba(255, 0, 0, 0.8),
      rgba(255, 0, 0, 0) 70.71%
    ),
    linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 70.71%),
    linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 70.71%); /* For old Opera (11.1 to 12.0) */
  background: linear-gradient(
      217deg,
      rgba(255, 0, 0, 0.8),
      rgba(255, 0, 0, 0) 70.71%
    ),
    linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 70.71%),
    linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 70.71%); /* Standard syntax; must be last */
  background-size: 300% 300%;
  animation: gradient 12s infinite ease-in-out;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

  h1 {
    margin-bottom: 10px;
  }
}

.social-media-bar {
  .App-link {
    color: white;
    margin: 0 5px;
    text-decoration: none;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 100% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  75% {
    background-position: 0% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}
